import React, { useState, memo, useEffect } from 'react';
import { ArrayParam, NumberParam, withDefault } from 'use-query-params';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import { checkExpried, configOkeReview, formatNameSubBrand, loaded } from '../../utils/helpers';
import { useStore } from '../../hooks';

const Sidebar = loadable(() => import('./Sidebar'));
const Breadcrumb = loadable(() => import('./Breadcrumb'));
const SortBy = loadable(() => import('./SortBy'));
const GridProducts = loadable(() => import('./GridProducts'));
const FilterCollapse = loadable(() => import('./FilterCollapse'));
const Desciption = loadable(() => import('./Description'));
const PromoImage = loadable(() => import('./PromoImage'));
const Pagination = loadable(() => import('./Pagination'));
const CategoryImage = loadable(() => import('./CategoryImage'));
const BreadcrumbBrand = loadable(() => import('./BreadcrumnBrand'));
const SlidebarScrollmagic = loadable(() => import('./SidebarScrollmagic'));
const SubCategorySlider = loadable(() => import('./SubCategorySlider'));
const BannerActiveskin = loadable(() => import('./BannerActiveskin'));

Sidebar.preload();
SubCategorySlider.preload();
Breadcrumb.preload();
SortBy.preload();
Desciption.preload();
PromoImage.preload();
CategoryImage.preload();
BreadcrumbBrand.preload();
SlidebarScrollmagic.preload();
BannerActiveskin.preload();

const ArchiveProduct = memo((props) => {
  const { dataFilter, siteConfig, filters } = useStore();

  const {
    promotion,
    customOrderBy,
    hideSortby = [],
    isBrand = false,
    state: { dataPage, products, loading },
  } = props;

  const { data = {} } = dataPage;
  const { filter_group } = dataFilter?.data || {};
  const { products_per_page } = siteConfig?.data || {};
  const _promotion =
    data?.promotion_image && data?.promotion_image?.gatsbyImageData ? data : promotion;
  const default_sort = data?.default_sort;

  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  const [sortBy, setSortBy] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginate, setPaginate] = useState({ total: 0, per_page: 0 });
  const [newProducts, setNewProducts] = useState(products);
  const [productHidecounter, setProductHidecounter] = useState({});
  const [dataFilters, setDataFilters] = useState({});
  const [initQuery, setInitQuery] = useState({});

  useEffect(() => {
    configOkeReview();
  }, []);

  useEffect(() => {
    let sort;
    if (default_sort) {
      const key = default_sort.toLowerCase().replaceAll(' ', '_');
      if (!hideSortby?.includes(key)) sort = [key, default_sort];
    }

    setSortBy(sort || ['best_sellers', 'Best Sellers']);
  }, [default_sort]);

  useEffect(() => {
    if (products.length < 1) return;
    const metas = { brand: {} };
    const metafields = [];

    products.forEach(({ id, vendor }) => {
      if (!isBrand && vendor !== data.name) {
        const _brand = metas.brand[vendor];
        metas.brand[vendor] = _brand ? [..._brand, id] : [id];
      }
      const filter = filters[id];
      if (filter) metafields.push({ id, nodes: filter });
    });

    metafields.forEach(({ id, nodes }) => {
      nodes.map((node) => {
        if (!metas[node.key]) {
          metas[node.key] = {};
        }
        const _meta = metas[node.key];
        let meta;
        try {
          meta = JSON.parse(node.value);
        } catch (error) {
          meta = [];
        }

        meta.forEach((key) => {
          metas[node.key][key] = _meta[key] ? [..._meta[key], id] : [id];
        });
      });
    });

    if (Object.keys(dataFilter).length > 0) {
      const _initQuery = {};
      filter_group?.forEach((v) => {
        _initQuery[v.metafield_key] = withDefault(ArrayParam, []);
      });
      _initQuery['page'] = withDefault(NumberParam, 1);

      setDataFilters(metas);
      setInitQuery(_initQuery);
    }
  }, [products]);

  useEffect(() => {
    let perPage = products_per_page;
    // if (!checkExpried(_promotion.p_start_date, _promotion.p_end_date)) {
    //   perPage =
    //     typeof window !== 'undefined' && window.innerWidth < 992
    //       ? _promotion?.promotion_image && _promotion?.promotion_image?.gatsbyImageData
    //         ? products_per_page + 1
    //         : products_per_page
    //       : _promotion?.promotion_image && _promotion?.promotion_image?.gatsbyImageData
    //       ? products_per_page + 3
    //       : products_per_page;
    // }

    const totalPage = Math.ceil(newProducts.length / perPage);
    setPaginate({ total: totalPage, per_page: perPage });
  }, [newProducts]);

  return (
    <div className="main__archive-product archive-product page container-xl">
      {data?.name &&
        (data?.parent_categories ? (
          <Breadcrumb
            className="d-block d-lg-none"
            current={data?.name}
            uid={data?.url}
            seo={true}
            categories={data?.parent_categories || []}
          />
        ) : (
          <BreadcrumbBrand
            className="d-block d-lg-none"
            current={data?.name}
            uid={data?.url}
            seo={true}
            categories={data?.parent_brand || []}
          />
        ))}

      <div className="row">
        <div className="col-md-12 px-0 px-lg-3">
          {data?.display_activeskin
            ? data?.banner_activeskin &&
              data?.banner_activeskin?.gatsbyImageData && <BannerActiveskin {...data} />
            : data?.display &&
              data?.banner &&
              data?.banner?.gatsbyImageData && (
                <CategoryImage link={data?.link} image={data?.banner} />
              )}
        </div>
      </div>

      <div className="row d-block d-lg-none">
        <div className="col-md-12 text-center">
          <div className="title">
            <h2>{data?.h1_title}</h2>
          </div>
        </div>
      </div>

      <div className="row align-items-center toolbar-pc mx-0">
        <div className="col-4 pl-0">
          {data?.parent_categories ? (
            <Breadcrumb
              className="d-none d-lg-block"
              current={data?.name}
              uid={data?.url}
              categories={data?.parent_categories || []}
            />
          ) : (
            <BreadcrumbBrand
              className="d-none d-lg-block"
              current={data?.name}
              uid={data?.url}
              categories={data?.parent_brand || []}
            />
          )}
        </div>

        <div className="col-4">
          {!loading && (
            <div className="total-product text-center">
              {newProducts.length - Object.keys(productHidecounter).length} products found
            </div>
          )}
        </div>

        <div className="col-4 pr-0">
          <SortBy
            state={sortBy}
            setState={setSortBy}
            customOrderBy={customOrderBy}
            hideSortby={hideSortby}
          />
        </div>
      </div>

      <div className="row align-items-center toolbar">
        <div className="col-6">
          <div className="title">
            <h1>{data?.h1_title}</h1>
          </div>
        </div>

        {/* <div className="col-6">
          {paginate.total > 1 && (
            <div className="pagination justify-content-end">
              <Pagination
                paginate={paginate}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div> */}
      </div>
      {/* {Object.keys(dataFilters)?.length > 0 && ( */}
      <div className="row">
        <aside id="sidebar" className="col-xl-2 col-lg-3">
          <SlidebarScrollmagic products={newProducts}>
            <div id="sidebar-content">
              <div id="sidebar-breadcrumb" className="d-none">
                {data?.parent_categories ? (
                  <Breadcrumb
                    className="d-none d-lg-block"
                    current={data?.name}
                    uid={data?.url}
                    categories={data?.parent_categories || []}
                  />
                ) : (
                  <BreadcrumbBrand
                    className="d-none d-lg-block"
                    current={data?.name}
                    uid={data?.url}
                    categories={data?.parent_brand || []}
                  />
                )}
              </div>
              <Sidebar
                showSidebarMobile={showSidebarMobile}
                setShowSidebarMobile={setShowSidebarMobile}
                products={products}
                setNewProducts={setNewProducts}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                dataFilters={dataFilters}
                initQuery={initQuery}
                dataFilter={dataFilter}
              >
                {data?.subcategories && data?.subcategories?.length > 0 && (
                  <FilterCollapse
                    className={loaded && window.innerWidth < 992 ? '--checkbox' : '--link'}
                    show={true}
                    title="Category"
                  >
                    <ul>
                      {data?.subcategories
                        ?.sort((a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
                        ?.map(({ name, url }, categoryIndex) => (
                          <li key={categoryIndex}>
                            <span>
                              <Link to={`/${url}`} activeClassName="active">
                                {formatNameSubBrand(name, data?._name || data?.name)}
                              </Link>
                            </span>
                          </li>
                        ))}
                    </ul>
                  </FilterCollapse>
                )}
              </Sidebar>
            </div>
          </SlidebarScrollmagic>
        </aside>
        <section id="main-content" className="main__archive-product__content col-xl-10 col-lg-9">
          <div className="toolbar-mobile">
            <div className="btn-filter" onClick={() => setShowSidebarMobile(true)}>
              Filter
            </div>

            <SortBy
              state={sortBy}
              setState={setSortBy}
              customOrderBy={customOrderBy}
              hideSortby={hideSortby}
            />

            <div className="page">
              <div className="page__total">
                {!loading && <p> {newProducts.length} products found</p>}
              </div>
            </div>
          </div>
          <div className="grid row">
            {!checkExpried(_promotion.p_start_date, _promotion.p_end_date) &&
              _promotion?.promotion_image &&
              _promotion?.promotion_image?.gatsbyImageData && (
                <PromoImage
                  link={_promotion?.promotion_link}
                  image={_promotion?.promotion_image}
                  lifestyle_image={_promotion?.promotion_lifestyle_image}
                  sub_title={_promotion?.promotion_sub_title}
                  tag={_promotion?.promotion_tag}
                  title={_promotion?.promotion_title}
                  button_text={_promotion?.promotion_button_text}
                />
              )}

            {paginate.per_page > 0 && (
              <GridProducts
                sortBy={sortBy}
                products={newProducts}
                currentPage={currentPage}
                totalItem={paginate.per_page}
                setCounter={setProductHidecounter}
              />
            )}
          </div>

          {paginate.total > 1 && (
            <div className="pagination justify-content-end">
              <Pagination
                paginate={paginate}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}

          <Desciption data={data?.bottom_description} />
        </section>
      </div>
      {/* )} */}
    </div>
  );
});

export default ArchiveProduct;
